import { render, staticRenderFns } from "./index.vue?vue&type=template&id=09245d78&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=09245d78&prod&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomepageBanner: require('/app/components/homepage/HomepageBanner.vue').default,HomepageCreative: require('/app/components/homepage/HomepageCreative.vue').default,HomepageEconomy: require('/app/components/homepage/HomepageEconomy.vue').default,HomepageInfo: require('/app/components/homepage/HomepageInfo.vue').default,HomepageContainer: require('/app/components/homepage/HomepageContainer.vue').default,HomepageJoin: require('/app/components/homepage/HomepageJoin.vue').default,HomepageEvents: require('/app/components/homepage/HomepageEvents.vue').default,HomepageCity: require('/app/components/homepage/HomepageCity.vue').default,HomepageMap: require('/app/components/homepage/HomepageMap.vue').default,HomepageMedia: require('/app/components/homepage/HomepageMedia.vue').default,HomepagePartners: require('/app/components/homepage/HomepagePartners.vue').default})
