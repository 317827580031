
import HomepageContainer from '~/components/homepage/HomepageContainer'
import HomepageDialog from '~/components/homepage/HomepageDialog'
import VButton from '~/components/ui/VButton'

export default {
    data() {
        return {
            selectedDot: null,
            dots: [{
                id: 1,
                title: this.$t('pages.index.map.dots.about.title'),
                text: this.$t('pages.index.map.dots.about.text'),
                route: '/about',
                position: {
                    left: '8%',
                    top: '30%',
                }
            },{
                id: 2,
                title: this.$t('pages.index.map.dots.accelerator.title'),
                text: this.$t('pages.index.map.dots.accelerator.text'),
                route: '/accelerator',
                dialog: {
                    position: 'right bottom'
                },
                position: {
                    right: '9%',
                    top: '10%',
                }
            },{
                id: 3,
                title: this.$t('pages.index.map.dots.services.title'),
                text: this.$t('pages.index.map.dots.services.text'),
                route: '/services',
                dialog: {
                    position: 'right bottom'
                },
                position: {
                    right: '27%',
                    top: '39%',
                }
            },{
                id: 4,
                title: this.$t('pages.index.map.dots.portfolio.title'),
                text: this.$t('pages.index.map.dots.portfolio.text'),
                route: '#',
                position: {
                    right: '55%',
                    top: '60%',
                }
            }]
        }
    },
    methods: {
        selectDot(id) {
            this.selectedDot = id
        }
    },
    components: {
        HomepageContainer,
        HomepageDialog,
        VButton
    }
}
