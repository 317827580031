
import HomepageMap from '~/components/homepage/HomepageMap'
import HomepageJoin from '~/components/homepage/HomepageJoin'
import HomepageInfo from '~/components/homepage/HomepageInfo'
import HomepageCity from '~/components/homepage/HomepageCity'
import HomepageMedia from '~/components/homepage/HomepageMedia'
import HomepageBanner from '~/components/homepage/HomepageBanner'
import HomepageEvents from '~/components/homepage/HomepageEvents'
import HomepageEconomy from '~/components/homepage/HomepageEconomy'
import HomepagePartners from '~/components/homepage/HomepagePartners'
import HomepageCreative from '~/components/homepage/HomepageCreative'
import NewsItem from '~/components/news/NewsItem'
import NewsItemBig from '~/components/news/NewsItemBig'
import { NEWS, EVENTS_AVAILABLE } from '~/constants/api'

export default {
    async asyncData({ $axios }) {
        const res = await $axios.get(NEWS, {
            params: {
                limit: 9,
                page: 1,
            }
        })
        const eventRes = await $axios.get(EVENTS_AVAILABLE, {
            params: {
                limit: 4,
                page: 1,
            }
        })
        return { 
            news: [{
                items: res.data.items
            }],
            events: eventRes.data.items,
            total: res.data.meta.total,
            nextPage: 2,
            totalLoadedItemsCount: res.data.items.length
        }
    },
    data() {
        return {
            news: [{
                items: []
            }],
            events: [],
            totalLoadedItemsCount: 0,
            total: 0,
            newsInGroup: 9,
            nextPage: 2,
            indexOfBigNews: [0,1,8]
        }
    },
    methods: {
        itemType(i) {
            if (this.indexOfBigNews.some(el => el === i)) {
                return 'news-item-big'
            }
            return 'news-item'
        },
    },
    components: {
        NewsItem,
        HomepageMap,
        NewsItemBig,
        HomepageCity,
        HomepageInfo,
        HomepageJoin,
        HomepageMedia,
        HomepageEvents,
        HomepageBanner,
        HomepageEconomy,
        HomepageCreative,
        HomepagePartners,
    }
}
