
import HomepageContainer from '~/components/homepage/HomepageContainer'
import VButton from '~/components/ui/VButton'

export default {
    data() {
        return {
            selector: 'creators'
        }
    },
    components: {
        HomepageContainer,
        VButton
    }
}
