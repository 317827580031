
export default {
    props: {
        data: {
            type: Object,
            required: true
        },
        visible: {
            type: Boolean,
            default: false
        }
    }
}
