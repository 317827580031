
import HomepageContainer from '~/components/homepage/HomepageContainer'
import EventCalendar from '~/components/event/EventCalendar'
import EventCard from '~/components/event/EventCard'
import VButton from '~/components/ui/VButton'

export default {
    components: {
        HomepageContainer,
        EventCalendar,
        EventCard,
        VButton
    },
    props: {
        events: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        eventColor(i) {
            switch (i) {
                case 1:
                    return 'lavanda'
                case 2:
                    return 'yellow'
                default:
                    return 'white'
            }
        }
    }
}
