
export default {
    props: {
        index: {
            type: Number,
            default: undefined
        },
        color: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        date() {
            if (this.item.created_at) {
                let date = new Date(this.item.created_at)
                return date.toLocaleDateString(this.$i18n.localeProperties.iso)
            }
            return ''
        },
        background() {
            if (this.color) {
                return this.color
            } else if (this.index !== undefined) {
                switch (this.index) {
                    case 2:
                        return 'yellow'
                    case 3:
                        return 'gray'
                    case 4:
                        return 'yellow'
                    case 5:
                        return 'dark'
                    case 6:
                        return 'purple'
                    case 7:
                        return 'gray'
                    default:
                        return 'yellow'
                }
            }
        },
        description() {
            if (this.item.short.length > 200) {
                return `${this.item.short.slice(0, 200)}...`
            }
            return this.item.short
        },
        title() {
            if (this.item.title.length > 40) {
                return `${this.item.title.slice(0, 40)}...`
            }
            return this.item.title
        }
    }
}
