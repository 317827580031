
import HomepageContainer from '~/components/homepage/HomepageContainer'
import VButton from '~/components/ui/VButton'

export default {
    components: {
        HomepageContainer,
        VButton
    }
}
