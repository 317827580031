
import HomepageContainer from '~/components/homepage/HomepageContainer'
import VButton from '~/components/ui/VButton'

export default {
    data() {
        return {
            selectedIndex: 0,
            items: [{
                selector: {
                    name: this.$t('pages.index.media.items[0].selector'),
                },
                media: {
                    type: 'image',
                    src: '/img/blog-01.png'
                },
                button: {
                    title: this.$t('general.learnMore'),
                    href: '/blog'
                },
                title: this.$t('pages.index.media.items[0].title'),
                text: this.$t('pages.index.media.items[0].description'),
            },{
                selector: {
                    name: this.$t('pages.index.media.items[1].selector'),
                },
                media: {
                    type: 'image',
                    src: '/img/Untitled-2-01.png'
                },
                title: this.$t('pages.index.media.items[1].title'),
                text: this.$t('pages.index.media.items[1].description'),
            },{
                selector: {
                    name: this.$t('pages.index.media.items[2].selector'),
                },
                media: {
                    type: 'iframe',
                    src: 'https://www.youtube.com/embed/0gRfnB9lyzs'
                },
                title: this.$t('pages.index.media.items[2].title'),
                text: this.$t('pages.index.media.items[2].description'),
                button: {
                    title: this.$t('general.learnMore'),
                    href: 'https://www.youtube.com/watch?v=0gRfnB9lyzs'
                }
            }]
        }
    },
    computed: {
        currentItem() {
            return this.items[this.selectedIndex]
        }
    },
    methods: {
        selectItem(index) {
            this.selectedIndex = index
        },
        prev() {
            if (this.selectedIndex === 0) {
                this.selectItem(this.items.length - 1)
            } else {
                this.selectItem(this.selectedIndex - 1)
            }
        },
        next() {
            if (this.selectedIndex === this.items.length - 1) {
                this.selectItem(0)
            } else {
                this.selectItem(this.selectedIndex + 1)
            }
        }
    },
    components: {
        HomepageContainer,
        VButton
    }
}
