
export default {
    props: {
        color: {
            type: String,
            default: 'white',
            validator: (value) => {
                return ['white', 'yellow', 'lavanda'].includes(value)
            }
        },
        event: {
            type: Object,
            required: true,
        }
    },
    computed: {
        description() {
            if (this.event.description.length > 200) {
                return `${this.event.description.slice(0, 200)}...`
            }
            return this.event.description
        },
        date() {
            let options = {
                weekday: "short",
                month: "long",
                day: "numeric",
            }
            return new Date(this.event.eventDate).toLocaleDateString(this.$i18n.localeProperties.iso, options)
        },
    }
}
