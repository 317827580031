
export default {
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    computed: {
        date() {
            if (this.item.created_at) {
                let date = new Date(this.item.created_at)
                return date.toLocaleDateString(this.$i18n.localeProperties.iso)
            }
            return ''
        },
        image() {
            return this.item.images.find(el => el.format === 'original')
        }
    }
}
