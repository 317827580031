
import HomepageContainer from '~/components/homepage/HomepageContainer'
import VButton from '~/components/ui/VButton'

export default {
    data() {
        return {
            selectedIndex: 0,
            items: [{
                selector: {
                    name: this.$t('pages.index.creative.items[0].name'),
                },
                text: this.$t('pages.index.creative.items[0].text'),
            },{
                selector: {
                    name: this.$t('pages.index.creative.items[1].name'),
                },
                text: this.$t('pages.index.creative.items[1].text'),
            }]
        }
    },
    computed: {
        currentItem() {
            return this.items[this.selectedIndex]
        }
    },
    methods: {
        selectItem(index) {
            this.selectedIndex = index
        }
    },
    components: {
        HomepageContainer,
        VButton
    }
}
