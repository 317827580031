import { render, staticRenderFns } from "./EventCard.vue?vue&type=template&id=34048b88&scoped=true&lang=pug&"
import script from "./EventCard.vue?vue&type=script&lang=js&"
export * from "./EventCard.vue?vue&type=script&lang=js&"
import style0 from "./EventCard.vue?vue&type=style&index=0&id=34048b88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34048b88",
  null
  
)

export default component.exports